import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@customer-formula-env/environment";
import { CustomerSubscription } from "@customer-formula/shared/models/customer.model";
import { PagedResponse } from "@kalmarenergi/util/models/response";
import { Observable } from "rxjs";
import { IGetAgentRequest, IGetSubscriptionsRequest } from "./customer.input";

@Injectable()
export class CustomerService {
  path = "v1/customer";

  /**
   * Constructor
   * @param http
   */
  constructor(private http: HttpClient) {}

  /**
   * Get subscriptions
   * @param request
   */
  getSubscriptions(
    request: IGetSubscriptionsRequest,
  ): Observable<PagedResponse<CustomerSubscription>> {
    return this.http.get<PagedResponse<CustomerSubscription>>(
      `${environment.baseApi}${this.path}/subscriptions`,
      {
        params: new HttpParams({ fromObject: request }),
      },
    );
  }

  /**
   * Get agent
   * @param request
   */
  getAgent(request: IGetAgentRequest): any {
    let httpParams = new HttpParams();
    for (const [key, value] of Object.entries(request)) {
      httpParams = httpParams.append(key, value);
    }
    return this.http.get<any>(`${environment.baseApi}${this.path}/agent`, {
      params: httpParams,
    });
  }
}
